<template>
  <div>
    <div>
      <el-form :inline="true" ref="ruleForm">
        <el-form-item>
          <el-select
            v-model="searchParams.parkId"
            :clearable="true"
            placeholder="请选择园区"
            multiple
            class="centerSelInput"
            @change="SearchPageData"
          >
            <el-option
              v-for="item in ParkOption"
              :key="item.val"
              :label="item.text"
              :value="item.val"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
					<el-cascader
						clearable
						ref="cascaderVehicleTypes"
						placeholder="请选择车辆类型"
						class="centerSelInput centerInput"
						v-model="searchParams.vehicleTypeIds"
						:options="CarTypeOption"
						:props="CarTypeOptionParams"
						:show-all-levels="false"
						@change="handleVehicleTypes"
					></el-cascader>
          <!--<el-select
            v-model="searchParams.carType"
            multiple
            :clearable="true"
            placeholder="请选择车辆类型"
            class="centerSelInput"
            @change="selectVehicleType"
          >
            <el-option
              v-for="item in CarTypeOption"
              :key="item.text"
              :label="item.text"
              :value="item.text"
            >
            </el-option>
          </el-select>-->
        </el-form-item>

        <el-form-item>
          <el-input
            :clearable="true"
            v-model="searchParams.carNumber"
            placeholder="请输入车辆编号"
            class="centerInput"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-date-picker
            v-model="searchParams.dateRange"
            :clearable="true"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd"
            class="centerDatepicker"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item>
          <button class="centerCzbtn" @click="SearchPageData">搜索</button>
        </el-form-item>
      </el-form>
    </div>

    <div class="analysis" v-loading="analysisLoading">
      <el-row>
        <el-col :span="5">
          <div class="analyAfter">
            <div>所选车辆数</div>
            <div>{{ statisticalAnalysisData.vehicleAmount ? statisticalAnalysisData.vehicleAmount : 0 }}辆</div>
          </div>
        </el-col>
        <el-col :span="5">
          <div class="analyAfter">
            <div>日均行驶里程</div>
            <div>{{ statisticalAnalysisData.dailyDriveMileage ? statisticalAnalysisData.dailyDriveMileage : 0 }}km</div>
          </div>
        </el-col>
        <el-col :span="5">
          <div class="analyAfter">
            <div>日均在线率</div>
            <div>{{ statisticalAnalysisData.dailyOnlineRate ? statisticalAnalysisData.dailyOnlineRate : 0 }}%</div>
          </div>
        </el-col>
        <el-col :span="5">
          <div class="analyAfter">
            <div>日均消耗SOC</div>
            <div>{{ statisticalAnalysisData.dailyAvgSocUsed ? statisticalAnalysisData.dailyAvgSocUsed : 0 }}%</div>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="analyAfterLast">
            <div>日均紧急制动次数</div>
            <div>
              {{ statisticalAnalysisData.dailyAvgEmergencyBrakeAmount ? statisticalAnalysisData.dailyAvgEmergencyBrakeAmount : 0 }}次
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="mt20 lineChart" v-loading="chartLoading">
      <div class="lineChartdv">
        <div
          id="ChartDayAvgRuntime"
          :style="{ width: '100%', height: '300px'}"
          v-if="chartData.dayAvgRuntime && chartData.dayAvgRuntime.length !== 0"
        ></div>

        <div v-if="chartData.dayAvgRuntime && chartData.dayAvgRuntime.length === 0">
          <p class="empty-title">每日平均运行时长（小时）</p>
          <el-empty :image="emptyImage" :image-size="100"></el-empty>
        </div>
      </div>

      <div class="lineChartdv">
        <div
          id="ChartDayAvgMileage"
          :style="{ width: '100%', height: '300px' }"
          v-if="chartData.dayAvgMileage && chartData.dayAvgMileage.length !== 0"
        ></div>

        <div v-else>
          <p class="empty-title">每日运营里程（km）</p>
          <el-empty :image="emptyImage" :image-size="100"></el-empty>
        </div>
      </div>

      <div class="lineChartdv1">
        <div class="chartWord">车辆充电数据分析</div>
        <div class="circleBox">
          <el-progress
            type="circle"
            :percentage="chartData.carChargeDataAnalyse !== null ? chartData.carChargeDataAnalyse.soc : 0"
            :show-text="false"
            :stroke-width="20"
            :width="200"
            color="#5693CC"
          ></el-progress>

          <div class="el-progress__text">
            <p>{{ chartData.carChargeDataAnalyse.soc ? chartData.carChargeDataAnalyse.soc : 0 }}%</p>
            <p>soc总体维持水平</p>
          </div>
        </div>
      </div>

      <div class="lineChartdv1">
        <div
          id="ChartDaySOC"
          :style="{ width: '100%', height: '300px' }"
          v-if="chartData.daySOC && chartData.daySOC.length !== 0"
        ></div>

        <div v-else>
          <p class="empty-title">每日平均消耗SOC（%）</p>
          <el-empty :image="emptyImage" :image-size="100"></el-empty>
        </div>
      </div>

      <div class="lineChartdv">
        <div
          id="ChartDayAvgBrakeNum"
          :style="{ width: '100%', height: '300px' }"
          v-if="chartData.carErrorDataAnalyse && chartData.carErrorDataAnalyse.dayAvgBrakeNum.length !== 0"
        ></div>

        <div v-else>
          <p class="empty-title">每日平均制动次数</p>
          <el-empty :image="emptyImage" :image-size="100"></el-empty>
        </div>
      </div>

      <div class="lineChartdv">
        <div style="text-align: right;">
          <el-button type="normal" size="mini" @click="checkAlarmDetail">查看详细数据</el-button>
        </div>
        <div
          id="ChartErrorTypeRate"
          :style="{ width: '100%', height: '300px' }"
          v-if="chartData.carErrorDataAnalyse && chartData.carErrorDataAnalyse.errorTypeRate.length !== 0"
        ></div>

        <div v-else>
          <p class="empty-title">紧急制动事件分布</p>
          <el-empty :image="emptyImage" :image-size="100"></el-empty>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { _CarTypeOption, _ParkOption, _getParkList } from "../../api/global";
import {
  _FindAnalysisData,
  _Overview,
  _DailyAnalysisData,
  _EmergencyBrake,
  _BrakeDetail,
} from "../../api/dataAnalysis/analysis";
import {_CarTypeAll} from "../../api/selCar";

export default {
  data() {
    return {
      emptyImage: require("../../assets/images/empty.png"),
      ParkOption: [],
      CarTypeOption: [],
			CarTypeOptionParams: {
				children: "children",
				label: "typeName",
				value: "id",
				// checkStrictly: true,
				multiple: true
			},
      analysisLoading: false,
      chartLoading: false,
      pickerOptions: {
        disabledDate(time) {
          // 禁止选择本日之后
          return time.getTime() > Date.now();
        },
      },
      searchParams: {
        parkId: [],
				vehicleTypes: "",
				vehicleTypeIds: "",
        carNumber: "",
        dateRange: [],
      },

      statisticalAnalysisData: {
        vehicleAmount: 0, //所选车辆数
        dailyDriveMileage: 0, //日均行驶里程
        dailyOnlineRate: 0, //日均在线率
        dailyAvgSocUsed: 0, //日均消耗SOC
        dailyAvgEmergencyBrakeAmount: 0, //日均故障次数
      },

      chartData: {
        analysisDate: "",
        dailyAvgMileage: [],
        autoDriveDailyAvgMileage: [],
        remoteDriveDailyAvgMileage: [],
        dailyAvgDuration: [],
        autoDriveDailyAvgDuration: [],
        remoteDriveDailyAvgDuration: [],
        dailyAvgSocUsed: "",
        dailyAvgSoc: "",

        dayOnlineRate: [], //每日上线率
        dayAvgRuntime: [], //每日平均运行时长
        dayAvgMileage: [], //每日平均里程
        dayAvgCarSpeed: [], //每日平均车速
        dayOrderNum: [], //每日订单数
        daySOC: [], //每日平均消耗SOC

        //车辆充电数据分析
        carChargeDataAnalyse: {
          soc: 0, //SOC总体维持水平
          dayChargeDuration: [], //每日充电时长
        },

        //车辆故障数据分析
        carErrorDataAnalyse: {
          errorTypeRate: [], //各故障类型占比，单位：次
          dayAvgBrakeNum: [], //每日平均每公里故障次数，单位：次
          dayAvgErrorNum: [], //每日平均故障次数，单位：次
          dayAvgKmErrorNum: [], //每日平均制动次数，单位：次
        },
      },
    };
  },
  mounted() {
    this.InitPageData();
    this.initDateRange()

  },
  methods: {
    async InitPageData() {
      await this.GetParkOption();
      await this.GetCarTypeOption();
      // await this.GetPageData();
      this.GetOverviewData();
      // this.GetDailyBusiness();
      // this.InitChart();
    },
    initDateRange() {
      let pre = new Date((new Date()).getTime() - 9 * 24 * 3600 * 1000);
      let today = new Date((new Date()).getTime() - 1 * 24 * 3600 * 1000);
      let monthBefore = `${pre.getFullYear()}-${(pre.getMonth() + 1) < 10 ? `0${(pre.getMonth() + 1)}` : (pre.getMonth() + 1)}-${(pre.getDate()) < 10 ? `0${(pre.getDate())}` : (pre.getDate())}`
      let monthNow = `${today.getFullYear()}-${(today.getMonth() + 1) < 10 ? `0${(today.getMonth() + 1)}` : (today.getMonth() + 1)}-${(today.getDate()) < 10 ? `0${(today.getDate())}` : (today.getDate())}`
      this.searchParams.dateRange = [monthBefore, monthNow]
    },
    //获取园区选项
    async GetParkOption() {
      let res = await _getParkList();

      if (res.code === 0) {
        this.ParkOption = res.data;
      }
    },

    //获取车辆类型选项
    async GetCarTypeOption() {
			let res = await _CarTypeAll();
	
			if (res.success) {
				this.CarTypeOption = res.data.list;
			}
    },
    selectVehicleType(e) {
    },
    checkAlarmDetail() {
      this.$router.push('/checkAlarmDetail')
    },
    //获取概览数据
    GetOverviewData() {
      let obj = {
        orgIds: this.searchParams.parkId ? this.searchParams.parkId : [],
				vehicleTypes: (this.searchParams.vehicleTypes === "") ? [] : this.searchParams.vehicleTypes,
        vehicleId: this.searchParams.carNumber,
        beginDate: this.searchParams.dateRange[0],
        endDate: this.searchParams.dateRange[1],
      };

      this.analysisLoading = true;
      this.chartLoading = true;
      this.statisticalAnalysisData = {}
      _Overview(obj).then((res) => {
        if (res.code === 0) {
          this.analysisLoading = false;
          this.chartLoading = false;
          // 统计数据
          this.statisticalAnalysisData.vehicleAmount = res.data.overview.vehicleAmount;
          this.statisticalAnalysisData.dailyDriveMileage = res.data.overview.dailyDriveMileage;
          this.statisticalAnalysisData.dailyOnlineRate = res.data.overview.dailyOnlineRate;
          this.statisticalAnalysisData.dailyAvgSocUsed = res.data.overview.dailyAvgSocUsed;
          this.statisticalAnalysisData.dailyAvgEmergencyBrakeAmount = res.data.overview.dailyAvgEmergencyBrakeAmount;
          this.chartData.carChargeDataAnalyse.soc = (res.data.overview.avgSoc === null) ? 0 : res.data.overview.avgSoc;
          
          if (!res.data.businesses || res.data.businesses.length === 0) {
            this.chartData.dayAvgMileage = []
            this.chartData.autoDriveDailyAvgMileage = []
            this.chartData.remoteDriveDailyAvgMileage = []
            this.chartData.dayAvgRuntime = []
            this.chartData.autoDriveDailyAvgDuration = []
            this.chartData.remoteDriveDailyAvgDuration = []
            this.chartData.daySOC = []
            this.InitChart()

          } else {
            // 图表数据
            this.chartData.dayAvgMileage = []
            this.chartData.autoDriveDailyAvgMileage = []
            this.chartData.remoteDriveDailyAvgMileage = []
            this.chartData.dayAvgRuntime = []
            this.chartData.autoDriveDailyAvgDuration = []
            this.chartData.remoteDriveDailyAvgDuration = []
            this.chartData.daySOC = []
            res.data.businesses && res.data.businesses.map(item => {
              // this.chartData.analysisDate.push(item.analysisDate)
              // this.chartData.dayAvgMileage = []
              this.chartData.dayAvgMileage.push({
                date: item.analysisDate.slice(5, item.analysisDate.length),
                val: item.dailyAvgMileage
              })
              // this.chartData.autoDriveDailyAvgMileage = []
              this.chartData.autoDriveDailyAvgMileage.push({
                date: item.analysisDate.slice(5, item.analysisDate.length),
                val: item.autoDriveDailyAvgMileage
              })
              // this.chartData.remoteDriveDailyAvgMileage = []
              this.chartData.remoteDriveDailyAvgMileage.push(
                {
                date: item.analysisDate.slice(5, item.analysisDate.length),
                val: item.remoteDriveDailyAvgMileage
              })
              // this.chartData.dayAvgRuntime = []
              this.chartData.dayAvgRuntime.push({
                date: item.analysisDate.slice(5, item.analysisDate.length),
                val: item.dailyAvgDuration
              })
              // this.chartData.autoDriveDailyAvgDuration = []
              this.chartData.autoDriveDailyAvgDuration.push({
                date: item.analysisDate.slice(5, item.analysisDate.length),
                val: item.autoDriveDailyAvgDuration
              })
              // this.chartData.remoteDriveDailyAvgDuration = []
              this.chartData.remoteDriveDailyAvgDuration.push({
                date: item.analysisDate.slice(5, item.analysisDate.length),
                val: item.remoteDriveDailyAvgDuration
              })
              // this.chartData.daySOC = []
              this.chartData.daySOC.push({
                date: item.analysisDate.slice(5, item.analysisDate.length),
                val: item.dailyAvgSocUsed
              })
              // this.chartData.dailyAvgSoc.push(item.dailyAvgSoc)
            })
            this.InitChart()
          }
					
          this.getBreakData()
        }
      });
    },
		
    getBreakData() {
      let obj = {
        orgIds: this.searchParams.parkId ? this.searchParams.parkId : [],
				vehicleTypes: (this.searchParams.vehicleTypes === "") ? [] : this.searchParams.vehicleTypes,
        vehicleId: this.searchParams.carNumber,
        beginDate: this.searchParams.dateRange[0],
        endDate: this.searchParams.dateRange[1],
      };
			
      _EmergencyBrake(obj).then(res => {
        let days = (Date.parse(obj.endDate) - Date.parse(obj.beginDate)) / (3600 * 24 * 1000) + 1
        if (res.code === 0) {
          if (JSON.stringify(res.data.dailyAmount) === "{}") {
            this.chartData.carErrorDataAnalyse.dayAvgBrakeNum = []
          } else {
            let series = [{
              name: '手动紧急制动',
              type: 'bar',
              stack: 'break',
              emphasis: {
                focus: 'series'
              },
              data: []
            },
            {
              name: '紧急制动-自动驾驶故障',
              type: 'bar',
              stack: 'break',
              emphasis: {
                focus: 'series'
              },
              data: []
            },
            {
              name: '紧急制动-远程驾驶故障',
              type: 'bar',
              stack: 'break',
              emphasis: {
                focus: 'series'
              },
              data: []
            }]
            this.chartData.carErrorDataAnalyse.dayAvgBrakeNum = []
            this.chartData.carErrorDataAnalyse.errorTypeRate = []
            series.forEach(serie => {
              for (let item in res.data.dailyAmount) {
                if (JSON.stringify(res.data.dailyAmount[item]).indexOf(serie.name) !== -1) {
                  res.data.dailyAmount[item].forEach(one => {
                    if (one.brakeType === serie.name) {
                      serie.data.push(one.brakeAmount)
                    }
                  })
                } else {
                  serie.data.push(0)
                }
              }
            })

            for ( let item in res.data.dailyAmount) {
              this.chartData.carErrorDataAnalyse.dayAvgBrakeNum.push({
                date: item.slice(5, item.length)
              })
            }
            this.chartData.carBreakTime = series

          }
          if (res.data.distribution.length === 0) {
            this.chartData.carErrorDataAnalyse.errorTypeRate = []
          } else {
            res.data.distribution.forEach((item) => {
            this.chartData.carErrorDataAnalyse.errorTypeRate.push({
              val: item.brakeAmount,
              date: item.brakeType
            })
          })
          }
          this.$nextTick(() => {
            this.InitChart1()
          })
         
        }
        
      })
    },
    //获取车辆日常数据
    GetDailyBusiness() {
      let obj = {
        orgIds: [],
        vehicleTypes: [],
        vehicleId: "",
        beginDate: this.searchParams.dateRange[0],
        endDate: this.searchParams.dateRange[1],
      };
			
      // _DailyAnalysisData(obj).then((res) => {

      //   if (res.code === 0) {
      //     this.chartData.analysisDate = res.data.analysisDate;
      //     this.chartData.dailyAvgMileage = res.data.dailyAvgMileage;
      //     this.chartData.autoDriveDailyAvgMileage = res.data.autoDriveDailyAvgMileage;
      //     this.chartData.remoteDriveDailyAvgMileage = res.data.remoteDriveDailyAvgMileage;
      //     this.chartData.dailyAvgDuration = res.data.dailyAvgDuration;
      //     this.chartData.autoDriveDailyAvgDuration = res.data.autoDriveDailyAvgDuration;
      //     this.chartData.remoteDriveDailyAvgDuration = res.data.remoteDriveDailyAvgDuration;
      //     this.chartData.dailyAvgSocUsed = res.data.dailyAvgSocUsed;
      //     this.chartData.dailyAvgSoc = res.data.dailyAvgSoc;
      //   }
      // });
    },
	
		handleVehicleTypes(){
			let objVehicleType = this.$refs.cascaderVehicleTypes.getCheckedNodes();
			let arrVehicleType = [];
			
			for (const vehicleTypeIds of this.searchParams.vehicleTypeIds) {
				for (const objType of objVehicleType) {
					if (vehicleTypeIds[1] === objType.value){
						arrVehicleType.push(objType.label);
					}
				}
			}
			
			this.searchParams.vehicleTypes = arrVehicleType;
			
			this.SearchPageData();
		},
	
		//获取页面数据
    async GetPageData() {
      this.analysisLoading = true;
      this.chartLoading = true;
      let jsonParams = {
        parkId: this.searchParams.parkId,
        carType: this.searchParams.carType,
        carNumber: this.searchParams.carNumber,
        beginDate: "",
        endDate: "",
      };

      if (this.searchParams.dateRange === null) {
        this.searchParams.dateRange = [];
      }

      if (this.searchParams.dateRange.length === 2) {
        jsonParams.beginDate = this.searchParams.dateRange[0];
        jsonParams.endDate = this.searchParams.dateRange[1];
      }

      let res = await _FindAnalysisData(jsonParams);
			
      if (res.success) {
        //region 统计数据
        this.statisticalAnalysisData.vehicleAmount = res.data.parkDataComm
          ? res.data.parkDataComm
          : 0;
        this.statisticalAnalysisData.dailyDriveMileage = res.data
          .dailyDriveMileage
          ? res.data.dailyDriveMileage
          : 0;
        this.statisticalAnalysisData.dailyOnlineRate = res.data.dailyOnlineRate
          ? res.data.dailyOnlineRate
          : 0;
        this.statisticalAnalysisData.dailyAvgSocUsed = res.data.dailyAvgSocUsed
          ? res.data.dailyAvgSocUsed
          : 0;
        this.statisticalAnalysisData.dailyAvgEmergencyBrakeAmount = res.data
          .dailyAvgEmergencyBrakeAmount
          ? res.data.dailyAvgEmergencyBrakeAmount
          : 0;
        //endregion 统计数据

        //region 图表数据
        let nChargeSoc = res.data.chart.carChargeDataAnalyse.soc;

        if (nChargeSoc !== "") {
          nChargeSoc = parseInt(Number(nChargeSoc));
        }

        this.chartData.dayOnlineRate = res.data.chart.dayOnlineRate;
        this.chartData.dayAvgRuntime = res.data.chart.dayAvgRuntime;
        this.chartData.dayAvgMileage = res.data.chart.dayAvgMileage;
        this.chartData.dayAvgCarSpeed = res.data.chart.dayAvgCarSpeed;
        this.chartData.dayOrderNum = res.data.chart.dayOrderNum;
        this.chartData.daySOC = res.data.chart.dailyAvgSocUsed;
        this.chartData.carChargeDataAnalyse.soc =
          nChargeSoc > 100 ? 100 : nChargeSoc;
        this.chartData.carChargeDataAnalyse.dayChargeDuration =
          res.data.chart.carChargeDataAnalyse.dayChargeDuration;
        this.chartData.carErrorDataAnalyse.errorTypeRate =
          res.data.chart.carErrorDataAnalyse.errorTypeRate;
        this.chartData.carErrorDataAnalyse.dayAvgErrorNum =
          res.data.chart.carErrorDataAnalyse.dayAvgErrorNum;
        this.chartData.carErrorDataAnalyse.dayAvgKmErrorNum =
          res.data.chart.carErrorDataAnalyse.dayAvgKmErrorNum;
        this.chartData.carErrorDataAnalyse.dayAvgBrakeNum =
          res.data.chart.carErrorDataAnalyse.dayAvgBrakeNum;
        //endregion 统计数据

        this.analysisLoading = false;
        this.chartLoading = false;
      }
    },

    async SearchPageData() {
      // await this.GetPageData();
      // this.chartData = {
      //   analysisDate: "",
      //   dailyAvgMileage: [],
      //   autoDriveDailyAvgMileage: [],
      //   remoteDriveDailyAvgMileage: [],
      //   dailyAvgDuration: [],
      //   autoDriveDailyAvgDuration: [],
      //   remoteDriveDailyAvgDuration: [],
      //   dailyAvgSocUsed: "",
      //   dailyAvgSoc: "",

      //   dayOnlineRate: [], //每日上线率
      //   dayAvgRuntime: [], //每日平均运行时长
      //   dayAvgMileage: [], //每日平均里程
      //   dayAvgCarSpeed: [], //每日平均车速
      //   dayOrderNum: [], //每日订单数
      //   daySOC: [], //每日平均消耗SOC

      //   //车辆充电数据分析
      //   carChargeDataAnalyse: {
      //     soc: 0, //SOC总体维持水平
      //     dayChargeDuration: [], //每日充电时长
      //   },

      //   //车辆故障数据分析
      //   carErrorDataAnalyse: {
      //     errorTypeRate: [], //各故障类型占比，单位：次
      //     dayAvgBrakeNum: [], //每日平均每公里故障次数，单位：次
      //     dayAvgErrorNum: [], //每日平均故障次数，单位：次
      //     dayAvgKmErrorNum: [], //每日平均制动次数，单位：次
      //   },
      // }

      this.GetOverviewData();
      // this.GetDailyBusiness();
      // this.getBreakData()
      // this.InitChart();
    },

    //region 图表
    //初始化图表
    InitChart() {
      // this.SetChartDayAvgMileage();
      // this.SetChartDaySOC();
    
    },
    InitChart1() {
      this.SetChartDayAvgRuntime();

      this.SetChartDaySOC();
      this.SetChartDayAvgMileage();

      this.SetChartErrorTypeRate();
      this.SetChartDayAvgBrakeNum();


    },

    //每日平均运行时长
    SetChartDayAvgRuntime() {
      if (this.chartData.dayAvgRuntime.length <= 0) return false;

      let arrDate = [];
      let arrValue = [];

      for (const item of this.chartData.dayAvgRuntime) {
        arrDate.push(item.date);
        arrValue.push(item.val);
      }
      let objChart = echarts.init(
        document.getElementById("ChartDayAvgRuntime")
      );

      this.DrawChart(
        objChart,
        "#2315c7",
        "每日平均运行时长（小时）",
        "",
        arrDate,
        arrValue
      );
    },

    //每日平均行驶里程
    SetChartDayAvgMileage() {
      if (this.chartData.dayAvgMileage.length <= 0) return false;

      let arrDate = [];
      let arrValue = [];
      let arrValue1 = []
      let arrValue2 = []
      for (const item of this.chartData.dayAvgMileage) {
        arrDate.push(item.date);
        arrValue.push(item.val);
      }
      for (const item of this.chartData.autoDriveDailyAvgMileage) {
        arrValue1.push(item.val)
      }
      for (const item of this.chartData.remoteDriveDailyAvgMileage) {
        arrValue2.push(item.val)
      }
      let objChart = echarts.init(
        document.getElementById("ChartDayAvgMileage")
      );
      let series = []
      series.push({
        name: '自动驾驶里程',
        type: 'bar',
        stack: 'mile',
        emphasis: {
          focus: 'series'
        },
        data: arrValue1
      })
      series.push({
        name: '远程驾驶里程',
        type: 'bar',
        stack: 'mile',
        emphasis: {
          focus: 'series'
        },
        data: arrValue2
      })
      this.DrawChart1(
        objChart,
        "#5e4e9e",
        "每日运营里程（km）",
        "",
        arrDate,
        arrValue,
        series
      );
    },

    //每日平均消耗SOC
    SetChartDaySOC() {
      if (this.chartData.daySOC.length <= 0) return false;

      let arrDate = [];
      let arrValue = [];

      for (const item of this.chartData.daySOC) {
        arrDate.push(item.date);
        arrValue.push(item.val);
      }

      let objChart = echarts.init(document.getElementById("ChartDaySOC"));
      this.DrawChart(
        objChart,
        "#d15d5d",
        "每日平均消耗SOC（%）",
        "",
        arrDate,
        arrValue
      );
    },

    //各故障类型占比
    SetChartErrorTypeRate() {
      if (this.chartData.carErrorDataAnalyse.errorTypeRate.length <= 0)
        return false;

      let arrChartData = [];

      for (const item of this.chartData.carErrorDataAnalyse.errorTypeRate) {
        arrChartData.push({
          name: item.date,
          value: item.val,
        });
      }

      let objChart = echarts.init(
        document.getElementById("ChartErrorTypeRate")
      );
      let option = {
        title: {
          text: "紧急制动事件分布",
          itemGap: 20,
          left: 20,
          top: 0,
          textStyle: {
            color: "#666",
            fontSize: 16,
            fontFamily:
              "Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif",
          },
          subtextStyle: {
            color: "#333",
            fontSize: 12,
          },
        },
        legend: {
          top: "bottom",
        },
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            type: "pie",
            radius: [20, 100],
            center: ["50%", "40%"],
            roseType: "area",
            itemStyle: {
              borderRadius: 8,
            },
            data: arrChartData,
            label: {
              normal: {
                show: true,
                // formatter: "{d}{b}\n{c}次",
              },
            },
          },
        ],
      };

      objChart.setOption(option);
    },

    //每日平均制动次数
    SetChartDayAvgBrakeNum() {
      if (!this.chartData.carErrorDataAnalyse.dayAvgBrakeNum || this.chartData.carErrorDataAnalyse.dayAvgBrakeNum.length <= 0)
        return false;

      let arrDate = [];
      let arrValue = [];

      for (const item of this.chartData.carErrorDataAnalyse.dayAvgBrakeNum) {
        arrDate.push(item.date);
        arrValue.push(item.val);
      }

      let objChart = echarts.init(
        document.getElementById("ChartDayAvgBrakeNum")
      );
      this.DrawChart1(
        objChart,
        "#c2516e",
        "每日平均制动次数",
        "",
        arrDate,
        arrValue,
        this.chartData.carBreakTime
      );
    },

    //柱状图绘制
    DrawChart1(chart, color, text, subtext, dateList, valList, series) {
      chart.setOption({
        title: {
          text,
          subtext,
          itemGap: 20,
          left: 20,
          top: 20,
          textStyle: {
            color: "#666",
            fontSize: 16,
            fontFamily:
              "Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif",
          },
          subtextStyle: {
            color: "#333",
            fontSize: 12,
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: true,
          data: dateList, //x轴：日期
          axisLabel: {
            textStyle: {
              align: "center",
            },
          },
        },
        yAxis: {
          type: "value",
        },
        grid: {
          top: "25%", // 等价于 y: '16%'
          left: "5%",
          right: "8%",
          bottom: "0%",
          containLabel: true,
        },
        series: series ? series : [
          {
            data: valList, //y轴：值
            type: "bar",
            smooth: false,
            symbol: "none",
            stack: "Ad",
            barWidth: 30,
            itemStyle: {
              normal: {
                // lineStyle: {
                //   color,
                // },
              },
            },
          },
        ],
      });
    },

    //折线图绘制
    DrawChart(chart, color, text, subtext, dateList, valList) {
      chart.setOption({
        title: {
          text,
          subtext,
          itemGap: 20,
          left: 20,
          top: 20,
          textStyle: {
            color: "#666",
            fontSize: 16,
            fontFamily:
              "Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif",
          },
          subtextStyle: {
            color: "#333",
            fontSize: 12,
          },
        },
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          spitNumber: 15,
          boundaryGap: true,
          data: dateList, //x轴：日期
          axisLabel: {
            interval: 'auto', //X轴信息全部展示
            // rotate: 30,
            // showMinLabel: true
          },
          minInterval: 1,
          splitLine: {
            // show: true
          }
        },
        yAxis: {
          type: "value",
        },
        grid: {
          top: "25%", // 等价于 y: '16%'
          left: "5%",
          right: "8%",
          bottom: "0%",
          containLabel: true,
        },
        series: [
          {
            data: valList, //y轴：值
            type: "line",
            smooth: false,
            symbol: "none",
            stack: "Total",
            itemStyle: {
              normal: {
                lineStyle: {
                  color,
                },
              },
            },
          },
        ],
      });

    },
    //endregion 图表
  },
};
</script>

<style scoped lang="less">
.empty-title {
  padding-left: 30px;
  padding-top: 10px;
  font-weight: bold;
  color: #666;
  font-size: 16px;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
}
.analysis {
  height: 100px;
  background-color: #fff;

  .analyAfter,
  .analyAfterLast {
    text-align: center;
    position: relative;
    top: 20px;
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    div:first-child {
      font-size: 14px;
      color: #666666;
    }

    div:last-child {
      font-size: 20px;
      color: #333333;
      font-weight: 600;
    }
  }

  .analyAfter:before {
    content: "";
    position: absolute;
    width: 1px;
    height: 35px;
    right: 0px;
    background-color: #d4d7d9;
  }
}

.circleBox {
  position: relative;
  top: 60px;
  display: flex;
  justify-content: center;
}

.el-progress {
  //   top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
}

.el-progress__text {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  margin-left: 0px;
}

.el-progress__text > p:first-child {
  font-size: 16px;
  font-weight: bold;
}

.el-progress__text > p:last-child {
  font-size: 12px;
  margin-top: 8px;
}

.lineChart {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .lineChartdv {
    background-color: #fff;
    height: 330px;
    width: 49%;
    margin-bottom: 20px;
  }

  .lineChartdv1 {
    background-color: #fff;
    height: 330px;
    width: 50%;
    margin-bottom: 20px;
    position: relative;

    .goDetail {
      font-size: 12px;
      color: #1683d9;
      position: absolute;
      right: 50px;
      top: 60px;
      cursor: pointer;
      z-index: 1;
    }

    .chartWord {
      font-size: 15px;
      font-weight: 600;
      color: #666666;
      transform: translate(20px, 20px);
    }
  }
}
</style>
